import React from 'react';

export default () => {
  return (
    <svg width="60" height="81" viewBox="0 0 60 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53 10V68H7V0H45L53 10Z" fill="#E3E3E3" />
      <path d="M53 10H45V0L53 10Z" fill="#CACACA" />
      <path d="M38.5 62.9375H21.5L0.25 44.875V81H59.75V44.875L38.5 62.9375Z" fill="#58A9FF" />
      <path d="M38.5 62.9375H21.5L0.25 81H59.75L38.5 62.9375Z" fill="#1565C0" />
      <path
        d="M38.925 63H21.075L0.25 45.4688V43.45L5.1375 38.5625L7 38.5V40.625L5.9875 40.6875L2.375 44.3V44.4062L21.925 60.875H38.075L57.625 44.4062V44.3L54.0125 40.6875L53 40.5V38.375L54.8625 38.5625L59.75 43.45V45.4688L38.925 63Z"
        fill="#2B2B2B"
      />
      <path d="M38.5 6.375H10.875V8.5H38.5V6.375Z" fill="#2B2B2B" />
      <path d="M30.875 12H10.875V14.125H30.875V12Z" fill="#2B2B2B" />
      <path d="M25.875 18H10.875V20.125H25.875V18Z" fill="#2B2B2B" />
      <path d="M35.875 24H10.875V26.125H35.875V24Z" fill="#2B2B2B" />
      <path d="M42.875 30H10.875V32.125H42.875V30Z" fill="#2B2B2B" />
      <path d="M30.875 35H10.875V37.125H30.875V35Z" fill="#2B2B2B" />
    </svg>
  );
};
