import React from 'react';

export default () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57 10V68H11V0H49L57 10Z" fill="#E3E3E3" />
      <path d="M57 10H49V0L57 10Z" fill="#CACACA" />
      <path d="M42.5 49.9375H25.5L4.25 31.875V68H63.75V31.875L42.5 49.9375Z" fill="#58A9FF" />
      <path d="M42.5 49.9375H25.5L4.25 68H63.75L42.5 49.9375Z" fill="#1565C0" />
      <path
        d="M42.925 51H25.075L4.25 33.4688V31.45L9.1375 26.5625L11 26.5V28.625L9.9875 28.6875L6.375 32.3V32.4062L25.925 48.875H42.075L61.625 32.4062V32.3L58.0125 28.6875L57 28.5V26.375L58.8625 26.5625L63.75 31.45V33.4688L42.925 51Z"
        fill="#2B2B2B"
      />
      <path d="M42.5 6.375H14.875V8.5H42.5V6.375Z" fill="#2B2B2B" />
      <rect x="15" y="12" width="38" height="24" fill="white" />
      <rect x="17" y="14" width="34" height="20" fill="#4CAF50" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4142 22.0711L27 23.4854L31.2426 27.728L32.6569 29.1422L34.0711 27.728L41.1421 20.6569L39.7279 19.2427L32.6569 26.3138L28.4142 22.0711Z"
        fill="white"
      />
    </svg>
  );
};
